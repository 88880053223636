<template>
  <base-view
    title="Visualizar presupuesto federal"
    description="Visualiza la información de los recursos federales pendientes, autorizados o rechazados"
    background="bg-gradient-purple-to-black"
    icon="repeat"
    header-type="overlap"
  >
    <b-card no-body>
      <b-card-header>
        <div class="row align-items-center">
          <div class="col">
            <check-authorization :requiresAuthorizations="['acceso periodos fiscales']">
              <router-link :to="{ name: 'PeriodosFiscales' }">
                <b-button size="sm">
                  Cambiar periodo fiscal activo
                </b-button>
              </router-link>
            </check-authorization>
          </div>
          <div class="col">
            <x-periodo-fiscal-tag classBody="col-4 offset-8 right-content" variant="primary" />
          </div>
        </div>
      </b-card-header>

      <div class="row py-1 p-4">
        <b-input-group>
          <template #prepend>
            <b-input-group-text>Filtrar por</b-input-group-text>
          </template>

          <b-form-select
            :options="[
                { text: 'Pendientes', value: 0 },
                { text: 'Autorizados', value: 1 },
                { text: 'Rechazados', value: 2 },
              ]"
            @input="onChangeEstado"
          >
          </b-form-select>
        </b-input-group>
      </div>

      <b-overlay :show="Boolean(loading)">
        <template #overlay>
          <loading></loading>
        </template>
        <div class="row my-1 p-4">
          <b-table
            :fields="fields"
            :items="traspasos"
            striped
            bordered
            responsive
            hover
            stacked="lg"
            show-empty
          >
            <template #cell(concepto)="data">
              <div class="text-primary text-center text-sm w-100">
                {{ data.item.concepto }}
              </div>
            </template>
            <template #cell(fecha)="data">
              <div class="text-primary text-center text-sm w-100">
                <b-badge variant="dark" class="ml-2">{{ data.item.fecha }}</b-badge>
              </div>
            </template>
            <template #cell(folio)="data">
              <div class="text-primary text-center text-sm w-100">
                <b-badge variant="primary" class="ml-2">{{ data.item.folio }}</b-badge>
              </div>
            </template>
            <template #cell(mes)="data">
              <div class="text-primary text-center text-sm w-100">
                <b-badge variant="secondary" class="ml-2">
                  {{ getMonthById(data.item.mes).value }}
                </b-badge>
              </div>
            </template>
            <template #cell(total)="data">
              <div class="text-primary text-center text-sm w-100">
                <b-badge variant="primary" class="ml-2">{{ $formatNumber(data.item.total) }}</b-badge>
              </div>
            </template>
            <template #row-details>
              <movimientos-list :traspaso="selectedTraspaso"></movimientos-list>
            </template>
            <template #cell(acciones)="row">
              <div class="d-flex justify-content-center align-items-center" style="font-size: 1rem">
                <b-button
                  class="mr-3 mb-2 mb-sm-0"
                  size="sm"
                  variant="info"
                  @click="
                    row.toggleDetails()
                    onShowMovimientos(row.item.id)
                  "
                >
                  Ver Movimientos
                </b-button>

                <check-authorization :requiresAuthorizations="['exportar traspasos']">
                  <b-button
                    class="mr-3 mb-2 mb-sm-0"
                    size="sm"
                    variant="primary"
                    @click="onExport(row.item.id, row.item.descripcion)"
                  >
                    Exportar
                  </b-button>
                </check-authorization>

                <check-authorization :requiresAuthorizations="['autorizar traspasos']">
                  <b-button
                    class="mr-3 mb-2 mb-sm-0"
                    size="sm"
                    variant="success"
                    @click="onAuthorize(row.item.id, 1)"
                  >
                    Autorizar
                  </b-button>
                </check-authorization>

                <check-authorization :requiresAuthorizations="['rechazar traspasos']">
                  <b-button
                    class="mr-3 mb-2 mb-sm-0"
                    size="sm"
                    variant="danger"
                    @click="onAuthorize(row.item.id, 0)"
                  >
                    Rechazar
                  </b-button>
                </check-authorization>

                <b-button
                  class="mr-3 mb-2 mb-sm-0"
                  size="sm"
                  variant="green"
                  @click="downloadAcuse(row.item.id, row.item.folio)">
                  Acuse
                </b-button>
              </div>
            </template>
          </b-table>
        </div>
      </b-overlay>
    </b-card>

    <b-modal
      v-model="showModal"
      :title="titleModal"
      size="sm"
      centered
      no-close-on-backdrop
    >
      <div v-if="authorize.autorizado === 1">
        <!-- Controles adicionales para la autorización -->
        <b-form-group id="fieldset-1" label="Folio de Autorización" label-for="input-folio"
                      invalid-feedback="Ingrese un folio de autorización">
          <b-form-input id="input-folio" v-model="folio_autorizacion"
                        :state="folio_state" placeholder="Ingrese el folio de autorización"></b-form-input>
        </b-form-group>
        <b-form-group id="fieldset-2" label="Fecha de Autorización" label-for="input-fecha"
                      invalid-feedback="Ingrese una fecha de autorización">
          <b-form-datepicker id="input-fecha" v-model="fecha_autorizacion"
                             :state="fecha_state" class="mb-2"></b-form-datepicker>
        </b-form-group>
      </div>
      <template #modal-footer="{ cancel }">
        <div class="d-flex">
          <b-button
            variant="outline-secondary"
            size="sm"
            @click="cancel()"
            class="mr-2"
          >
            Cancelar
          </b-button>

          <b-button variant="success" size="sm" @click="confirm()">Confirmar</b-button>
        </div>
      </template>
    </b-modal>
  </base-view>
</template>

<script>
import { mapGetters } from 'vuex'
import MovimientosList from '@/components/RecursosFederales/MovimientosList'

export default {
  name: 'PresupuestoFederalListView',

  components: {
    MovimientosList
  },

  data () {
    return {
      fields: ['descripcion', 'fecha', 'autorizado', 'total', 'acciones'],

      loading: false,

      selectedTraspaso: null,

      traspasos: null,

      showModal: false,

      modal_error: false,

      titleModal: '',

      authorize: [],

      folio_autorizacion: '',
      fecha_autorizacion: ''
    }
  },

  computed: {
    ...mapGetters('UIModule', ['getMonthById']),
    folio_state () {
      return !this.modal_error ? null : !(this.folio_autorizacion === '')
    },
    fecha_state () {
      return !this.modal_error ? null : !(this.fecha_autorizacion === '')
    }
  },

  methods: {
    async onChangeEstado (value) {
      this.loading = true
      this.periodoActivo = await this.$getPeriodoFiscalActivo()

      const traspasos = await this.$store.dispatch('recursoFederalModule/listar', {
        periodo_fiscal_id: this.periodoActivo.id,
        autorizado: value
      })

      this.traspasos = traspasos.data
      this.loading = false
    },

    onShowMovimientos (id) {
      this.selectedTraspaso = this.traspasos.find(el => el.id === id)
    },

    async downloadAcuse (id, folio) {
      const getval = await this.$store.dispatch('recursoFederal/downloadAcuse', id)

      await this.$onDownload(getval, `acuse.${folio}.pdf`)

      this.$notify({ message: `Solicitud de descarga de acuse de traspaso con folio ${folio}.` })
    },

    async onExport (id, folio) {
      const getval = await this.$store.dispatch(
        'recursoFederalModule/export',
        id
      )

      await this.$onDownload(getval, `recurso-federal-ingresos-propios.${folio}.xlsx`)

      this.$notify({
        message: `Solicitud de descarga de recurso federal/ingresos propios con folio ${folio}.`
      })
    },

    async reload () {
      await this.onChangeEstado(this.selected)
    },

    onAuthorize (id, esAutorizado) {
      this.titleModal = esAutorizado ? 'Autorizar traspaso' : 'Rechazar traspaso'
      this.showModal = true
      this.modal_error = false

      this.authorize = {
        traspaso_id: id,
        autorizado: esAutorizado
      }
    },

    async confirm () {
      this.showModal = false
      this.loading = true

      if (this.authorize.autorizado && ((this.folio_autorizacion === '') ||
        (this.fecha_autorizacion === ''))) {
        this.$notify({ error: 422, message: 'Debe proporcionar la información' })
        this.modal_error = true
        this.showModal = true
        this.loading = false
        return
      }

      this.authorize.confirmation = true
      this.authorize.folio = this.folio_autorizacion
      this.authorize.fecha = this.fecha_autorizacion

      const { error, message } = await this.$store.dispatch('recursoFederalModule/autorizarRecursoFederal', this.authorize)

      if (error) {
        this.$notify({ error, message })
      } else {
        this.$notify({ message: message })
        this.reload()
      }

      this.loading = false
    }
  }
}
</script>
